
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { ObjectPurposeType } from '@ntag-ef/finprocess-enums';
import { sort } from 'fast-sort';

import { IListGroup, IListItem, IListResult, IReferenceTuple } from '../../interfaces';
import { HelperService } from '../../services';
import { DebtorState, FinancingMapState, HouseholdState, RealEstateState } from '../../statemanagement/states';
import { PropertyTyp } from '../../types';

/**
 * Dialog zur Auswahl des Dokumenttyps für hochgeladene Dateien/Dokumente
 */
@Component({
    selector: 'ucba-tag-list-dialog',
    templateUrl: './tag-list-dialog.component.html',
    styleUrls: ['./tag-list-dialog.component.scss'],
})
export class TagListDialogComponent {
    public mainList: Array<{
        label: string,
        required: boolean,
        groups: IListGroup[],
    }> = [];

    public isLoading = true;

    public arrayRef = Array;

    public referenceLabels: Record<PropertyTyp, Record<string, string>>;

    /**
     * Standard Konstruktor
     * lädt alle Dokumententypen
     * 
     * @param {MatDialogRef<TagListDialogComponent>} dialogRef Referenz auf den Dialog
     * @param {IListGroup[]} listgroups Array aus den Dokumententypen
     * @param {Store} store Store injector
     */
    public constructor(
        public dialogRef: MatDialogRef<TagListDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public listgroups: IListGroup[],
        private store: Store,
    ) {
        // eslint-disable-next-line no-console
        // console.log(listgroups);

        this.referenceLabels = {
            'financingMapId': {},
            'realEstateId': {},
            'householdId': {},
            'debtorId': {},
        };

        // TODO die Übersetzungen auslagern

        const currentId = this.store.selectSnapshot(FinancingMapState.currentId) ?? '';
        this.referenceLabels['financingMapId'][currentId] = 'Finanzplan';

        const realEstates = this.store.selectSnapshot(RealEstateState.current) ?? [];
        realEstates.forEach(realEstate => {
            this.referenceLabels['realEstateId'][realEstate.id] = (realEstate.objectPurpose !== ObjectPurposeType.Finance) ?
                realEstate.name ?? '' : 'Zukünftiges Objekt';
        });

        const housholds = this.store.selectSnapshot(HouseholdState.current) ?? [];
        housholds.forEach((houshold, idx) => {
            this.referenceLabels['householdId'][houshold.id] = `${(idx + 1)}. Haushalt / Einkommen`;
        });

        const debtors = this.store.selectSnapshot(DebtorState.current) ?? [];
        debtors.forEach((debtor, idx) => {
            const name = HelperService.calcDebitorLabel(debtor, idx, 'Kreditnehmer')
            this.referenceLabels['debtorId'][debtor.id] = name;
        });

        // Erst nach Pflichtfeldern, dann nach Alphabet sortieren
        for (const group of this.listgroups) {
            group.items = sort(group.items).by([
                { desc: it => !!it.hasRequired },
                { asc: it => it.name },
            ]);
        }
        // um das zuklappen zu verbergen
        setTimeout(() => {
            this.isLoading = false;
        }, 100);

        
    }

    /**
     * wenn ein Listenelement angeklickt wird, schließt sich der Dialog und übergibt das Item
     * 
     * @param {IListItem} item ListItem was übergeben wird
     * @param {IReferenceTuple} reference die Referenc auf die ziel entität
     */
    public onItemClicked(item: IListItem, reference: IReferenceTuple): void {
        const result: IListResult = {
            name: item.name,
            area: item.area,
            metaType: item.metaType,
            documentType: item.documentType,
            referenceProperty: item.referenceProperty,
            referenceId: reference.referenceId,
        };

        this.dialogRef.close(result);
    }

    /**
     * schließen des Dialogs
     */
    public cancel(): void {
        this.dialogRef.close();
    }
}
